.mobile-menu {
  margin: calc($grid-gutter-width/2) $grid-gutter-width 0;
  @extend .d-flex;
  @extend .flex-row;
  @extend .justify-content-between;
  @extend .align-items-center;
  .nav-open-trigger {
    height: 3rem;
    width: 4rem;
  }
  .logo {
    padding: 0 0;
  }
}
.sidenav {
  .nav {
    margin-top: 1rem;
  }
  .nav-link {
    color: #ccc;
  }
  .nav-link:hover {
    color: #fff;
  }
  [class^="fa-"] {
    margin-right: .5rem;
  }
  .level2 {
    margin-left: 1.5rem;
  }
  @include media-breakpoint-down(md) {
    .logo {
      display: none;
    }
  }
}
