.modal-roles {
  input[type="checkbox"] {
    margin-right: 1rem;
  }
  label {
    display: inline;
  }
  .user-role {
    margin-bottom: 1rem;
  }
}