.queue-by-name {
  .queue-count {
    @extend .d-flex;
    @extend .flex-column;
    @extend .justify-content-center;
    @extend .align-items-center;
    background-color: #666;
    border-radius: 50%;
    font-size: 1.2rem;
    width: 3rem;
    height: 3rem;
    float: left;
    margin-right: 1rem;
    .count {
      font-size: 1rem;
    }
  }
  .queue-group {
    margin-bottom: 2rem;
  }
}
