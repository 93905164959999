label {
  display: block;
  font-size: 0.8rem;
  text-transform: uppercase;
}

textarea,
select,
input[type=number],
input[type=text] {
    display: block;
  width: 100%;
  border: 1px solid #333;
  padding: .5rem .5rem;
  margin-bottom: .8rem;
  border-radius: 8px;
}

textarea {
  height: 10em;
}
.form-outline .form-control ~ .form-label {
  color: #999;
}

.form-control {
  color: #ccc !important;
}