.queue-info-form {
    .town-row {
        @extend .d-flex;
        @extend .flex-row;
        @extend .justify-content-start;
        @extend .align-items-center;
        /*
        .town-input {
            width: 80%;
        }
        .town-action {
            width: 20%;
        }
        */
    }
    .queue-row {
        margin-bottom: 1rem;
    }
}