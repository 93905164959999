h2 {
  margin-bottom: 1rem;
}

.heading-with-action {
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-between;
  .action {
      font-size: 1.3rem;
  }
}

.as-text {
    color: inherit;
    background-color: transparent;
    border: 0px solid #fff;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link.active:hover {
  color: $primary
}

blockquote {
  margin: 0 2rem;
}