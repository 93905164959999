.logo {
    padding: 1.5rem 1rem .5rem;
    font-family: Viga, Helvetica, Arial, sans-serif;
    text-align: center;
    font-size: 2.5rem;
    .nv {
        display: inline;
    }
    .lux {
        display: inline;
        // color: $primary;
    }
}