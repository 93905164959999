.home {
  .logo {
      width: 200px;
      height: 100px;
      
      position: absolute;
      top:0;
      bottom: 0;
      left: 0;
      right: 0;
      
      margin: auto;
  }
}